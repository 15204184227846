import React from 'react';
import {
    Autocomplete,
    TextField,
    Box,
    Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';


// import { cos } from 'react-native-reanimated';
interface Option {
    id: string;
    label: string;
}

interface MultipleSelectWithChipsProps {
    options: Option[];
    placeholder: string;
    selectedItems: string[];
    onItemsChange: (name: string, items: string[]) => void;
    name: string;
    uploadedData: any
}

interface MultipleSelectWithChipsState {
    inputValue: string
}

class MultipleDropDown extends React.Component<MultipleSelectWithChipsProps, MultipleSelectWithChipsState> {
    constructor(props: MultipleSelectWithChipsProps) {
        super(props);
        this.state = {
            inputValue: ''
        };
    }

    handleChange = (event: any, value: Option[]) => {
        const selectedIds: string[] = value.map(item => item.id);
        this.props.onItemsChange(this.props.name, selectedIds); // Call the parent's onItemsChange with the updated value
    };
    handleInputChange = (event: any, value: string) => {
        this.setState({ inputValue: value });
    }
    handleDelete = (itemToDelete: Option) => {
        const updatedItems = this.props.selectedItems.filter(item => item !== itemToDelete.id);
        this.props.onItemsChange(this.props.name, updatedItems);
    };

    clearSelections = () => {
        this.props.onItemsChange(this.props.name, []);
    };

    render() {
        const { inputValue } = this.state;
        const { selectedItems, options, placeholder, name } = this.props;
        const selectedOptions = options.filter((option: any) => {
            return selectedItems.includes(option.id)
        });

        return (
            <Box >
                <Autocomplete
                    multiple
                    options={options}
                    getOptionLabel={(option) => option.label}
                    value={selectedOptions}
                    onChange={this.handleChange}
                    disableCloseOnSelect
                    inputValue={inputValue}
                    onInputChange={this.handleInputChange}
                    clearOnBlur={false}
                    noOptionsText={
                        <>
                            <Typography>No results found for '{inputValue}'</Typography>
                            {inputValue && name === 'aircraftTypes' &&
                                (
                                    <Typography
                                        // variant="outlined" 
                                        sx={{
                                            borderTop: '0.01px solid #CBD5E1', padding: '5px 10px 5px 10px',
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",

                                        }}
                                    >
                                        Add '{inputValue}'
                                    </Typography>
                                )}
                        </>
                    }
                    renderOption={(props, option) => {
                        // const isSelected = selectedItems.includes(option.id);
                        const isInUploadedData = this.props.uploadedData?.some((data: any) =>
                            data.designation.includes(option.id)
                        );
                        return (
                            <Box
                                component="li"
                                {...props}
                                sx={{
                                    padding: '10px 3px 10px 3px',
                                    display: 'flex',
                                    alignItems: 'centre',
                                    justifyContent: 'space-between',
                                    height: '48px',
                                    width: '100%',
                                }}
                            >
                                <Typography sx={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    padding: '10px 3px 10px 3px',
                                    flexGrow: 1,
                                }}>{option.label}</Typography>

                                {isInUploadedData && (
                                    <CheckIcon
                                        sx={{
                                            width: '15px',
                                            height: '15px',
                                            border: '1px solid #00AFEF',
                                            borderRadius: '50%',
                                            color: '#00AFEF',
                                            fontSize: '20px',
                                            justifySelf: 'end',
                                            padding: '2px'
                                        }}
                                    />
                                )}

                            </Box>
                        );
                    }}
                    sx={{
                        '& .MuiInputBase-root': {
                            border: '1px solid #CBD5E1',
                            '&:hover': {
                                border: '1px solid blue',
                                borderColor: 'darkgray',
                            },
                        },
                        width: '100%',
                        height: '50px',
                        padding: '10px 0px 10px 0px',
                    }
                    }
                    renderInput={(params) => {
                        const currentIndex = this.props.options.findIndex(opt => opt.id === this.props.selectedItems[this.props.selectedItems.length - 1]);
                        const nextIndex = currentIndex + 1;
                        return (
                            <TextField
                                {...params}
                                placeholder={selectedOptions.length > 0 ? '' : placeholder} // Use placeholder prop
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {selectedOptions?.length > 0 ?
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Typography sx={{
                                                        fontFamily: 'Montserrat,sans-serif',
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                        lineHeight: '24px',
                                                        textAlign: 'left',
                                                        color: '#94A3B8',
                                                        height: '15px',
                                                        width: '15px',
                                                        border: '2px solid #94A3B8',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        justifySelf: 'end !important',
                                                        margin: '0px 4px 0px 0px',
                                                        padding: '2px'
                                                    }}>{nextIndex}</Typography>
                                                    <Typography
                                                        key={selectedOptions[selectedOptions.length - 1].id}
                                                        sx={{
                                                            borderRadius: '12px',
                                                            padding: '4px 8px',
                                                            fontSize: '16px',
                                                            fontFamily: 'Montserrat,sans-serif',
                                                            lineHeight: '24px',

                                                        }}
                                                    >
                                                        {selectedOptions[selectedOptions.length - 1].label}
                                                    </Typography>
                                                </Box> : null

                                            }

                                        </>

                                    ),
                                    endAdornment: null,
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&::placeholder': {
                                            color: '#94A3B8',
                                            opacity: 1,
                                        },
                                        borderRadius: '20px',
                                    },
                                }}
                            />
                        )
                    }}
                />
            </Box>
        );
    }
}
export default MultipleDropDown