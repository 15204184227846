// Customizable Area Start
import React from "react";
import {
  Box,
  TextField,
  Typography,
  styled,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import SettingChangePasswordController, {
  Props,
} from "./SettingChangePasswordController";

export default class SettingChangePassword extends SettingChangePasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box sx={webStyle.headerContainer}>
          <Typography sx={webStyle.headerText}>Change password</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <StyledContainer>
            <Box className="form">
              <Box className="fieldContainer">
                <Typography className="fieldLabel">Current password</Typography>
                <StyledTextField
                  placeholder="Enter current password"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => {}} edge="end">
                          {true ? (
                            <VisibilityOff sx={{ color: "#94A3B8" }} />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box className="fieldContainer">
                <Typography className="fieldLabel">New password</Typography>
                <StyledTextField
                  placeholder="Enter a new password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => {}} edge="end">
                          {true ? (
                            <VisibilityOff sx={{ color: "#94A3B8" }} />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box className="fieldContainer">
                <Typography className="fieldLabel">
                  Confirm new password
                </Typography>
                <StyledTextField
                  placeholder="Confirm your new password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => {}} edge="end">
                          {true ? (
                            <VisibilityOff sx={{ color: "#94A3B8" }} />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button className="saveButton">Save changes</Button>
            </Box>
          </StyledContainer>
        </Box>
      </>
    );
  }
}


const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  alignItems: "center",
  maxWidth: "clamp(400px, 50vw, 860px)",
  background: "#ffffff",
  borderRadius: "20px",
  marginTop: "40px",
  width: "100%",
  padding: "20px",

  "& .form": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    maxWidth: "80%",
    minWidth:"400px",
    width: "100%",

    "& .fieldContainer": {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      "& .fieldLabel": {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "16px",
        color: "#334155",
      },
    },

    "& .saveButton": {
      background: "#0074BF",
      color: "#ffffff",
      borderRadius: "20px",
      textTransform: "none",
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "16px",
      height: "50px",
      padding: "0px",
      opacity: "30%",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "50px",
    borderRadius: "20px",
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0074BF",
    },
    "& input": {
      padding: "0 14px",
      height: "100%",
      boxSizing: "border-box",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
  },
});

const webStyle = {
  headerContainer: {
    display: "flex",
    height: "60px",
    padding: "10px 50px 10px 50px",
    alignItems: "center",
    background: "#ffffff",
  },
  headerText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    color: "#0074BF",
  },
};
// Customizable Area End
