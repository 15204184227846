import { BlockComponent } from "../../../framework/src/BlockComponent";

import {
  lockIcon,
  flyingFlight,
  rightFlight,
  cardFlight,
  termsIcon,
  phoneIcon,
  privacyIcon
} from './assets'
import React from "react";


export interface Props {
  navigation: any;
  id: string;
}

interface S {
  settingsMenuOpen: boolean
  // menuItems: any
  settingMenu: any
  expanded: boolean
  messagesMenu: any
  selectedTab: string
}

interface Option {
  id: string;
  label: string;
}

interface SS {
  id: number;
}

export default class SideBarController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
   
    this.state = {
      settingsMenuOpen: true,
      selectedTab: 'settings',
      settingMenu: [
        { label: 'Change password', icon: lockIcon.default },
        { label: 'Airport settings', icon: flyingFlight.default },
        { label: 'Tests settings', icon: cardFlight.default },
        { label: 'Aircraft types', icon: rightFlight.default },
        { label: 'Terms and conditions', icon: termsIcon.default },
        { label: 'Privacy policy', icon: privacyIcon.default },
        { label: 'Contact us', icon: phoneIcon.default },
      ],
      messagesMenu: [
        { label: 'Change password', icon: rightFlight.default },
        { label: 'Terms and conditions', icon: termsIcon.default},
        { label: 'Privacy policy', icon: privacyIcon.default },
      ],

      expanded: false
    }
  }

}
