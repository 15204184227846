import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
} from "@mui/material";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Box sx={webStyle.headerContainer}>
          <Typography sx={webStyle.headerText}>Terms and conditions</Typography>
        </Box>
        <StyledContainer>
          <Box className="termsAndConditionsContainer">
            <Typography className="content">
              {`Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary.`}
            </Typography>
            <Typography className="content">
              {`It’s also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact – there is no difference. `}
            </Typography>
            <Typography className="content">
              {`In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first.`}
            </Typography>
            <Typography className="content">
              {`By agreeing to the terms and conditions, users acknowledge their responsibilities and understand the guidelines set forth. This agreement helps to ensure a smooth, transparent relationship between the user and the provider, minimizing potential disputes or misunderstandings. Failure to adhere to these terms could result in restricted access or termination of services. Therefore, it’s crucial to read and fully understand the conditions before proceeding.`}
            </Typography>
          </Box>
        </StyledContainer>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "40px",
  "& .termsAndConditionsContainer": {
    maxWidth: "860px",
    display:"flex",
    flexDirection:"column",
    background:"#ffffff",
    padding:"30px",
    gap:"10px",
    borderRadius:"20px",
    margin:"0px 20px 0px 20px",
    '& .content':{
      fontFamily:"Montserrat",
      fontWeight:400,
      fontSize:"16px",
      textAlign:"justify"
    }
  },

  "@media (max-width: 600px)": {
    marginTop: "20px",
   "& .termsAndConditionsContainer": {
      maxWidth: "100%",
      flexGrow:1,
   }
  },
});

const webStyle = {
  headerContainer: {
    display: "flex",
    height: "60px",
    padding: "10px 50px 10px 50px",
    alignItems: "center",
    background: "#ffffff",
  },
  headerText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    color: "#0074BF",
  },
};
// Customizable Area End
