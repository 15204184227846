import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  Grid,
  IconButton,
  styled
} from "@mui/material";


import { loginLogo, loginImage } from "./assets";

import { Link } from 'react-router-dom';

import * as Yup from "yup";
import { Formik } from "formik";
import LoginController, { Props } from "./LoginController.web";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// Customizable Area End

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
     // Customizable Area Start
    return (
      <>
        <div className="Toastify"></div>
        <LoginContainer>
        <Grid container style={{ width: "100%", height: "100%",}}>
          <Grid item xs={12} sm={12} md={6} sx={{height: "100%"}}
          >
            <Box >
              <img src={loginLogo} style={{ width: "150px", height: "61px", padding: "32px 0px 0px 40px" }} alt="Login Logo" />
            </Box>
            <Box className= "loginFormContainer">
            <Formik
              initialValues={{
                password: "",
                type: "Technician",
                email: "",
              }}
              data-test-id="formik"
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email format")
                  .required("Email is required"),
                type: Yup.string().required("Type is required"),
                password: Yup.string()
                  .nullable()
                  .required("Password is required"),
              })}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={() => {
                this.handleLogin();
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Box sx={webStyle.formWrapper}>
                    <Box sx={webStyle.inputWrapper}>
                      <Typography variant="h6" sx={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 700, fontSize: "22px", marginBottom: '15px', color: "#001C55", lineHeight: '24px'
                      }}>
                        Welcome back!
                      </Typography>
                        {this.state.loginErrors &&
                            <Box sx={webStyle.alertBox}>
                              <Typography style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: "12px", color: "#DC2626", lineHeight: '18px' }}>We couldn’t find a match for that email and password.
                                Please double-check and try again.</Typography>
                            </Box>
                        }
                    </Box>
                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Email</InputLabel>
                      <TextField
                        data-test-id={"email"}
                        name="email"
                        id="email"
                        value={props.values.email}
                        onChange={(event) => {
                          props.setFieldValue("email", event.target.value);
                          this.onValueChange("email", event.target.value);
                        }}
                        size="medium"
                        fullWidth={true}
                        variant="outlined"
                        type="text"
                        onBlur={props.handleBlur}
                        placeholder="Enter your email"
                        sx={{
                          "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #CBD5E1",
                              borderWidth: "1px",
                            },
                          },
                          backgroundColor: "#ffffff",
                          color: "#94A3B8",
                          mb: "3px",
                        }}

                        InputProps={{
                          style: {
                            border: "1px solid #CBD5E1",
                            borderRadius: '20px',
                            fontWeight: 400,
                            width: "100%"

                          }
                        }}
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "email"
                      )}
                    </Box>
                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Password</InputLabel>
                      <TextField
                        id="password"
                        data-test-id={"password"}
                        value={props.values.password}
                        onChange={(event) => {
                          props.setFieldValue("password", event.target.value);
                          this.onValueChange("password", event.target.value);
                        }}
                        size="medium"
                        name="password"
                        onBlur={props.handleBlur}
                        variant="outlined"
                        placeholder="Enter your password"
                        fullWidth={true}
                        type={this.state.showPassword ? "text" : "password"}
                        sx={{
                          "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #CBD5E1",
                              borderWidth: "1px",
                            },
                          },
                          backgroundColor: "#ffffff",
                          color: "#94A3B8",
                          mb: "3px",
                        }}

                        InputProps={{
                          style: {
                            border: "1px solid #CBD5E1",
                            width: "100%",
                            borderRadius: '20px',
                            fontWeight: 400
                          },
                          endAdornment: (
                            <IconButton
                              onClick={() => this.togglePasswordVisibility('showPassword')}
                              edge="end"
                            >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          ),
                        }}
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "password"
                      )}

                    </Box>
                    <Box display={"flex"} justifyContent={"end"} sx={{ width: '100%', maxWidth: '360px' }}>

                      <Typography variant="h6" ><Link to={'/forgot-password'} style={{
                        fontFamily: 'Montserrat, sans-serif', display: "inline",
                        fontWeight: 700, fontSize: "14px", color: "#0074BF", textDecoration: "none",
                        marginLeft: "7px"
                      }}>
                        Forgot password?
                      </Link>
                      </Typography>
                    </Box>

                    <Button
                      type="submit"
                      sx={webStyle.buttonStyle}
                      data-test-id="LoginBtn"
                      variant="contained"
                    >
                      Login
                    </Button>

                    <Typography variant="h6" align="center" sx={{
                      fontFamily: 'Montserrat, sans-serif',
                      fontWeight: 400, fontSize: "16px", color: "#0F172A",
                      display: "inline"
                    }}>
                      Don’t have an account?
                      <Link to="/" style={{
                        fontFamily: 'Montserrat, sans-serif', display: "inline",
                        fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                        marginLeft: "7px"
                      }}>
                        Sign up
                      </Link >
                    </Typography>

                  </Box>
                </form>
              )}
            </Formik>
            </Box>
          </Grid>
          <Grid item xs={12} sm= {12} md={6} padding={0} className="rightSideDisplay">
            <img src={loginImage} style={{width: "100%" }} />
          </Grid>
        </Grid>
        </LoginContainer>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: "360px",
    mb: 1
  },
  mainWrapper: {
    paddingBottom: "30px",
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    justifyContent: "center",
    height: "100vh",
  },
  buttonStyle: {
    height: "50px",
    width: "100%",
    maxWidth: "360px",
    marginTop: "25px",
    marginBottom: "50px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none"
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "3px",
    color: "#334155"
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  alertBox: {
    backgroundColor: "#FEE2E2",  
    borderRadius: "10px", 
    padding: "12px 8px", 
    display: "flex",
    alignItems: "center",
    maxWidth: "360px",
    fontSize: "14px",
    borderLeft:"4px solid red",
    margin: "37px 0px 30px 0px"
  },
};

const LoginContainer = styled(Box)({
  height: "auto",
  "& .loginFormContainer": {
    overflow: "auto",
    height: "auto",
    overflowX: "hidden",
    marginTop: "100px",
    "@media(max-width:1242)": {
      marginTop: "70px",
    },
    "@media(max-width:960px)": {
      padding: "0px 30px",
      boxSizing: "border-box",
      marginTop: "50px",
      height: "auto",
      width: "100%",
    },
    "@media(min-width: 360px) and (max-width: 414px)": {
      padding: "0px 30px",
      height: "auto",
      marginTop: "30px",
    },
  },
  "& .rightSideDisplay": {
    "@media (max-width: 899px)": {
      display: "none"
    }
  },

})
// Customizable Area End