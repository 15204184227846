import React from "react";
import {
  Typography,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


  const textStyles = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'justify',
    color: '#0F172A'
  };
  
  const TermsAndConditionsModal = ({ open, onClose }: any) => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth
        PaperProps={{
          style: { borderRadius: 16 }
        }}>
        <DialogTitle sx={{
          borderBottom: "1px solid #E2E8F0",
          fontSize: '22px',
          fontWeight: 700,
          lineHeight: '32px',
          textAlign: 'left',
          color: "#001C55"
        }}>
          Terms and Conditions
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon sx={{ width: "18px", height: "18px", color: "#0074BF" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ marginTop: 4 }}>
          <DialogContentText>
            <Typography paragraph sx={textStyles}>
              Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary.
            </Typography>
            <Typography paragraph sx={textStyles}>
              It's also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact  there is no difference.
            </Typography>
            <Typography paragraph sx={textStyles}>
              In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first.
            </Typography>
            <Typography paragraph sx={textStyles}>
              By agreeing to the terms and conditions, users acknowledge their responsibilities and understand the guidelines set forth. This agreement helps to ensure a smooth, transparent relationship between the user and the provider, minimizing potential disputes or misunderstandings. Failure to adhere to these terms could result in restricted access or termination of services. Therefore, it's crucial to read and fully understand the conditions before proceeding.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };
  export default TermsAndConditionsModal;
