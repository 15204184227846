import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    InputLabel,
    Button,
    TextField,
    Grid,
    IconButton
    // Customizable Area End
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { loginLogo, loginImage } from "../../email-account-login/src/assets";

import { Link } from 'react-router-dom';

// Customizable Area Start
import ResetPasswordController, { Props } from "./ResetPasswordController";

// import For, { Props } from "./ForgotPasswordController.web";
// Customizable Area End

export default class ForgotPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { password, showPassword, showConfirmPassword, confirmPassword, errors } = this.state;
        const { handleInputChange, handleSubmit, togglePasswordVisibility } = this;
        return (
            // Customizable Area Start
            <>
                <div className="Toastify"></div>
                <Grid container direction={'row'} >
                    <Grid item xs={6}>
                        <img src={loginLogo} style={{ height: "10%", width: "25%", padding: "15px" }} />
                        <Grid sx={
                            {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '90%',
                                overflow: 'auto'
                            }
                        }>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center'
                                }}
                            >


                                {!this.state.isFormSubmitted && <form onSubmit={handleSubmit}>
                                    <Box sx={webStyle.formWrapper}>

                                        <Box sx={webStyle.inputWrapper}>
                                            <Typography variant="h6" sx={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                fontWeight: 700, fontSize: "22px", color: "#001C55"

                                            }}>
                                                New password!
                                            </Typography>
                                            <Typography variant="h6" sx={{
                                                margin: '15px 0px',
                                                fontFamily: 'Montserrat, sans-serif',
                                                fontWeight: 400, fontSize: "16px", color: "#0F172A"
                                            }}>

                                                Please choose a new password for john.doe@example.com                          </Typography>

                                            <Box
                                                sx={webStyle.inputWrapper}>
                                                <InputLabel
                                                    style={webStyle.label}>
                                                    Password
                                                </InputLabel>
                                                <TextField
                                                    name="password"
                                                    type={showPassword ? "text" : "password"}
                                                   
                                                    InputProps={{
                                                        style: webStyle.inputStyle,
                                                        endAdornment: (
                                                            <IconButton
                                                                onClick={() => togglePasswordVisibility('showPassword')}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    value={password}
                                                    onChange={(e) => handleInputChange("password", e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Enter Password"
                                                    sx={webStyle.textFieldStyle}
                                                    error={!!errors.password}
                                                    helperText={errors.password}
                                                />
                                                <Box sx={webStyle.inputWrapper}>
                                                    <InputLabel style={webStyle.label}>Confirm Password</InputLabel>
                                                    <TextField
                                                    
                                                        name="confirmPassword"
                                                       
                                                        InputProps={{
                                                            style: webStyle.inputStyle,
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={() => togglePasswordVisibility('showConfirmPassword')}
                                                                    edge="end"
                                                                >{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        placeholder="Confirm your password"
                                                        sx={webStyle.textFieldStyle}
                                                        error={!!errors.confirmPassword}
                                                        helperText={errors.confirmPassword}
                                                        value={confirmPassword}
                                                        onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box sx={webStyle.passwordRequirements}>
                                                    {this.passwordRequirements.map((req, index) => (
                                                        <Box key={index} sx={webStyle.requirementItem}>
                                                            <CheckCircleIcon
                                                                sx={this.getPasswordRequirementIconStyle(req, password)}
                                                            />
                                                            <Typography sx={webStyle.requirementText}>
                                                                {req.text}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Button
                                            type="submit"
                                            sx={webStyle.buttonStyle}
                                            data-test-id="LoginBtn"
                                            variant="contained"
                                        >
                                            Set a new password
                                        </Button>

                                        <Typography variant="h6" align="center" sx={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 400, fontSize: "16px", color: "#0F172A",
                                            display: "inline"
                                        }}>
                                            Back to
                                            <Link to="/Login" style={{
                                                fontFamily: 'Montserrat, sans-serif', display: "inline",
                                                fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                                                marginLeft: "7px"
                                            }}>
                                                Login
                                            </Link >
                                        </Typography>

                                    </Box>

                                </form>
                                }

                                {this.state.isFormSubmitted && <Box sx={webStyle.formWrapper}>

                                    <Box sx={webStyle.inputWrapper}>
                                        <Typography variant="h6" sx={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 700, fontSize: "22px", color: "#001C55"

                                        }}>
                                            Check your inbox!
                                        </Typography>
                                        <Typography variant="h6" sx={{
                                            margin: '15px 0px',
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 400, fontSize: "16px", color: "#0F172A"
                                        }}>
                                            We have sent a password reset link to john.doe@example.com
                                        </Typography>

                                    </Box>
                                    <Button
                                        type="submit"
                                        sx={webStyle.recoveryButton}
                                        data-test-id="LoginBtn"
                                        variant="outlined"
                                    >
                                        Send a recovery link
                                    </Button>
                                    <Typography variant="h6" align="center" sx={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight: 400, fontSize: "16px", color: "#0F172A",
                                        display: "inline"
                                    }}>Back to
                                        <Link to="/Login" style={{
                                            fontFamily: 'Montserrat, sans-serif', display: "inline",
                                            fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                                            marginLeft: "7px"
                                        }}>Login
                                        </Link >
                                    </Typography>
                                </Box>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} padding={0}><img src={loginImage} style={{ height: "100vh", width: "100%" }} />
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

const webStyle = {

    recoveryButton: {
        height: "50px",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        padding: '10px 20px',
        border: "2px solid #0074BF",
        backgroundColor: "#ffffff",
        color: "#0074BF",
        borderRadius: '20px',
    },
    requirementText: {
        fontSize: '14px',
        color: '#333',
        fontFamily: 'Montserrat, sans-serif',
    },
    inputWrapper: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1
    },
    mainWrapper: {
        paddingBottom: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        justifyContent: "center",
        height: "100vh",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    buttonStyle: {
        height: "50px",
        width: "360px",
        marginTop: "10px",
        marginBottom: "10px",
        border: "none",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none",
        fontFamily: 'Montserrat, sans-serif',
        lineHeight: "24px",
        fontSize: '16px',
        borderRadius: "20px",
    },
    label: {
        fontWeight: 600,
        marginTop: "3px",
        color: "#334155",
        fontSize: "16px",
    },
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        // height: '350px'
    },
    passwordRequirements: {
        marginTop: '10px',
    },
    requirementItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
    },
    textFieldStyle: {
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CBD5E1",
                borderWidth: "1px",
            },
        },
        backgroundColor: "#ffffff",
        color: "#94A3B8",
        mb: "3px",
    },
    inputStyle: {
        border: "1px solid #CBD5E1",
        borderRadius: '20px',
        fontWeight: 400,
        width: "100%"
    },
};