import React from 'react';
import { Box, Button, FormControlLabel, Checkbox, DialogContent, Dialog, DialogTitle, DialogActions, IconButton, InputLabel, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import MultipleSelectWithChips from "./components/MultipleSelectWithChips";
import { Link } from 'react-router-dom';
import MultipleSelectWithCheckBox from "./components/MultipleSelectWithCheckBox";
import { leftArrow, warning, airPlane } from "./assets";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';


interface AccountSetupStep1Props {
    formValues: any;
    onChangeItems: (name: string, value: string[]) => void;
    handleAccordionChange: any
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void; // Define the type for onSubmit
    deleteDesignationRequest: any
    handlePrevious: () => void
    deleteAircraftTest: (id: string) => void
    getlabels: any
}
interface states {
    openTest: boolean,
    openItem: boolean,
    checked: boolean,
    expanded: boolean
    testDialog: boolean
    currentTest: string
    currentTestId: string | null,
    currentSelectedItems: any
}
class AccountSetupStep2 extends React.Component<AccountSetupStep1Props, states> {
    constructor(props: AccountSetupStep1Props) {
        super(props);
        this.state = {
            openTest: false,
            openItem: false,
            checked: false,
            expanded: false,
            testDialog: false,
            currentTest: '',
            currentTestId: null,
            currentSelectedItems: null
        };
    }
    handleDeleteClick = (id: string, test: string) => {
        // Handle the deletion logic here
        this.setState((prevState) => ({
            ...prevState,
            openTest: true,
            currentTest: test,
            currentTestId: id
        }));
    };
    handleCloseDialog = () => {
        this.setState((prevState) => ({
            ...prevState,
            openTest: false,
            currentTest: '',
            currentTestId: null,
            openItem: false,
            currentSelectedItems: null
        }));
    };

    handleConfirmDelete = () => {
        this.props.deleteDesignationRequest(this.state.currentTestId)
        this.props.deleteAircraftTest(this.state.currentTestId || '')
        this.handleCloseDialog()
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checked: event.target.checked });
    };
    handleChanges = (event: React.SyntheticEvent, isExpanded: boolean) => {
        this.setState((prev) => ({ ...prev, expanded: !this.state.expanded })); // Set state based on accordion's expanded/collapsed state
    };
    showNextField = (fieldName: string) => {
        const { formValues } = this.props;
        switch (fieldName) {
            case 'category':
                return formValues.gradeOfCirtificates?.length > 0;
            case 'class':
                return formValues.category?.length > 0;
            case 'aircraftTypes':
                return formValues.class?.length > 0;
            default:
                return false;
        }
    };
    handleDeleteItemPopup = (name: string, selectedItems: any) => {
        this.setState((prevState) => ({
            ...prevState,
            openItem: true,
            currentSelectedItems: {
                name,
                selectedItems
            }
        }));
    };
    handleConfirmDeleteItem = () => {
        if (this.state?.currentSelectedItems) {
            this.props.onChangeItems(this.state?.currentSelectedItems?.name, this.state?.currentSelectedItems.selectedItems);
        }
        this.handleCloseDialog()
    }

    isFormValid = () => {
        const { formValues } = this.props;
        const hasGradeCertificate = formValues.gradeOfCirtificates?.length > 0;
        const hasCategory = formValues.category?.length > 0;
        const hasClass = formValues.class?.length > 0;
        const hasAircraftType = formValues.aircraftTypes?.length > 0;

        // If grade certificate is selected, category is required
        if (hasGradeCertificate && !hasCategory) {
            return false;
        }

        // If category is selected, class is required
        if (hasCategory && !hasClass) {
            return false;
        }

        // If class is selected, aircraft type is required
        // if (hasClass && !hasAircraftType) {
        //     return false;
        // }

        // All previous fields must be filled
        return hasGradeCertificate && hasCategory && hasClass
    };

    render() {
        const { formValues, getlabels, handlePrevious, onSubmit, onChangeItems, handleAccordionChange } = this.props;

        return (
            <Box sx={webStyle.formWrapper}>
                <Box sx={webStyle.inputWrapper}>
                    <Typography variant="h6" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 700,
                        fontSize: "22px",
                        color: "#001C55",
                    }} >
                        <img src={leftArrow} style={{ padding: '7px', height: '21px', width: '24px' }} onClick={() => handlePrevious()} />
                        On the right track!
                    </Typography>

                    <Typography variant="h6" sx={{
                        margin: '15px 0px',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#0F172A",
                    }}>

                        Let’s pick the tests you’ll be offering:
                    </Typography>
                    <Typography variant="h6" sx={{
                        margin: '15px 0px',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        fontSize: "18px",
                        color: "#5274B5",
                    }}>
                        <img src={airPlane.default} style={{ marginRight: '8px' }} />
                        What types of tests do you do?
                    </Typography>
                </Box>

                <Grid container sx={{
                    minWidth: formValues.class.length === 0 ? '365px' : '800px'
                }}>
                    <Grid item xs={6} >
                        <Accordion expanded={formValues.firstAccordionExpanded}
                            onChange={(event, isExpanded) => handleAccordionChange('firstAccordionExpanded', isExpanded)}
                            sx={{ boxShadow: 'none', border: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{
                                    width: '20px',
                                    height: '20px',
                                    opacity: '0px', color: '#5274B5', backgroundColor: 'rgb(227, 231, 238);',
                                    borderRadius: '50%'
                                }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse', // Use row-reverse to position items
                                    alignItems: 'center',
                                    padding: 0,
                                }}
                            >
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    color: '#5274B5',
                                    marginLeft: '20px',
                                    whiteSpace: 'nowrap'
                                }}>Test specifications</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0 }}>
                                <Box sx={webStyle.inputWrapper}>
                                    <InputLabel style={webStyle.label}>Grade of certificate</InputLabel>
                                    <MultipleSelectWithCheckBox name='gradeOfCirtificates'
                                        selectedItems={formValues.gradeOfCirtificates}
                                        onItemsChange={onChangeItems}
                                        options={formValues.gradeOptions}
                                        placeholder="Select a grade of certificate"
                                        popUpHandler={this.handleDeleteItemPopup}
                                    />
                                </Box>

                                {formValues.gradeOfCirtificates?.length > 0 && (
                                    <Box sx={webStyle.inputWrapper}>
                                        <InputLabel style={webStyle.label}>Category</InputLabel>
                                        <MultipleSelectWithCheckBox name='category' onItemsChange={onChangeItems} selectedItems={formValues.category}
                                            options={formValues.categoryOptions}
                                            placeholder="Select category"
                                            popUpHandler={this.handleDeleteItemPopup}
                                        />
                                    </Box>)}
                                {this.showNextField('class') && (<Box sx={webStyle.inputWrapper}>
                                    <InputLabel style={webStyle.label}>Class</InputLabel>
                                    <MultipleSelectWithCheckBox name='class' selectedItems={formValues.class} onItemsChange={onChangeItems}
                                        options={formValues.classOptions}
                                        placeholder="Select class"
                                        popUpHandler={this.handleDeleteItemPopup}
                                    />
                                </Box>)}
                                {this.showNextField('aircraftTypes') && (
                                    <Box sx={webStyle.inputWrapper}>
                                        <InputLabel style={webStyle.label}>Aircraft type</InputLabel>
                                        <MultipleSelectWithChips popUpHandler={this.handleDeleteItemPopup} options={formValues.aircraftTypeOptions} name='aircraftTypes' placeholder="Enter aircraft type" selectedItems={formValues.aircraftTypes} onItemsChange={onChangeItems} />
                                    </Box>)}

                            </AccordionDetails>
                        </Accordion>
                        {!formValues.firstAccordionExpanded &&
                            <Box>
                                <Typography sx={webStyle.selectedOptions}>{getlabels(formValues.gradeOptions, formValues.gradeOfCirtificates)}</Typography>
                                <Typography sx={webStyle.selectedOptions}>{getlabels(formValues.categoryOptions, formValues.category)}</Typography>
                                <Typography sx={webStyle.selectedOptions}>{getlabels(formValues.classOptions, formValues.class)}</Typography>
                                <Typography sx={webStyle.selectedOptions}>{getlabels(formValues.aircraftTypeOptions, formValues.aircraftTypes)}</Typography>
                            </Box>
                        }
                    </Grid>

                    {/* Add a second accordion here if needed */}
                    {formValues.class.length > 0 && <Grid item xs={6}>
                        <Accordion expanded={formValues.secondAccordionExpanded}
                            onChange={(event, isExpanded) => handleAccordionChange('secondAccordionExpanded', isExpanded)}
                            sx={{ boxShadow: 'none', border: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{
                                    width: '20px',
                                    height: '20px',
                                    opacity: '0px', color: '#5274B5', backgroundColor: 'rgb(227, 231, 238);',
                                    borderRadius: '50%'
                                }} />}
                                aria-controls="panel2-content"
                                // IconButtonProps={{edge: 'start'}}
                                id="panel2-header"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse', // Use row-reverse to position items
                                    alignItems: 'center',
                                    padding: 0,
                                    '& .MuiAccordionSummary-content': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }

                                    // fontFamily:'sans-serif'
                                }}
                            >
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    color: '#5274B5',
                                    marginLeft: '20px'
                                }}>Tests</Typography>
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    color: '#FFFFFF',
                                    height: '25px',
                                    width: '25px',
                                    backgroundColor: '#5274B5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    justifySelf: 'end !important',
                                    marginLeft: '8px',
                                }}>{formValues.addedAirCraftTypes.length}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ ...webStyle.inputWrapper, maxWidth: '380px' }}>
                                    {formValues.addedAirCraftTypes.map((test: any, index: number) => (
                                        <Box key={test.id} sx={{
                                            display: 'flex', justifyContent: 'space-between',
                                            alignItems: 'center', marginBottom: '8px',
                                            border: '1px solid #CBD5E1',
                                            borderRadius: '15px',
                                            padding: '10px 10px',
                                            width: '360px'

                                        }}>
                                            <Typography sx={{ width: '30px', boxSizing: 'border-box' }}>
                                                <Typography sx={{
                                                    fontFamily: 'Montserrat,sans-serif',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    lineHeight: '24px',
                                                    textAlign: 'left',
                                                    color: '#5274B5',
                                                    height: '24px',
                                                    width: '24px',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    justifySelf: 'end !important',
                                                    margin: '0px 0px 0px 0px',
                                                    border: '1px solid #94A3B8'
                                                }}>{index + 1}</Typography>
                                            </Typography>

                                            <Typography sx={{
                                                fontFamily: 'Montserrat,sans-serif',
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "24px",
                                                textAlign: 'left',
                                                paddingLeft: '5px'

                                            }}>{test.value}</Typography>
                                            <IconButton onClick={() => this.handleDeleteClick(test.id, test.value)}>
                                                <DeleteOutlineIcon sx={{ color: '#F87171' }} />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </AccordionDetails>

                        </Accordion>
                        {<Dialog PaperProps={{
                            style: {
                                width: '600px',
                                height: '290px',
                                borderRadius: '10px'
                            },
                        }} open={this.state.openTest} onClose={this.handleCloseDialog} >
                            <DialogTitle sx={{
                                fontFamily: 'Montserrat,sans-serif',
                                fontSize: "20px",
                                fontWeight: 700,
                                lineHeight: "28px",
                                textAlign: 'left',
                                color: '#001C55',
                                padding: '20px 35px',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '0.1px solid #e8e8e8',

                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton sx={{ backgroundColor: '#E0F2F8', marginRight: '20px' }}>
                                        <DeleteOutlineIcon sx={{ color: '#0074BF' }} />
                                    </IconButton>
                                    Delete test?
                                </Box>
                                <IconButton
                                    onClick={this.handleCloseDialog}
                                    sx={{
                                        position: 'absolute',
                                        right: '10px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: '20px', color: '#94A3B8' }} />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent sx={{
                                padding: '0',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    textAlign: 'inline',
                                    color: '#0F172A',
                                    padding: '20px 30px',
                                    // display: 'inline-flex',
                                    alignContent: 'center',
                                    boxSizing: 'border-box'

                                }}><span>Are you sure you want to remove </span>
                                    {this.state.currentTest ? <span style={{ fontWeight: 480 }}>{this.state.currentTest}</span> : ''}
                                    <span> test? This action cannot be undone.</span>
                                </Typography>
                            </DialogContent>

                            <DialogActions sx={{
                                display: 'flex',
                                borderTop: '0.1px solid #e8e8e8',
                                justifyContent: 'center', margin: '0'
                            }}>
                                <Button
                                    type="button"
                                    onClick={this.handleCloseDialog}
                                    sx={{
                                        ...webStyle.cancelbuttonStyle,
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            color: '#0074BF',
                                        }
                                    }}
                                    data-test-id="LoginBtn"
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    onClick={this.handleConfirmDelete}
                                    sx={{ ...webStyle.buttonStyle }} // Make button full width
                                    data-test-id="LoginBtn"
                                    variant="contained"
                                >
                                    Yes, delete test
                                </Button>

                            </DialogActions>
                        </Dialog>}
                        <>
                            {
                                !formValues.secondAccordionExpanded && formValues.addedAirCraftTypes.map((addedAirCraftType: any, index: number) =>
                                (
                                    <Typography key={index} sx={webStyle.selectedOptions}>{addedAirCraftType.value}</Typography>
                                ))
                            }
                        </>
                    </Grid>}
                    {<Dialog PaperProps={{
                        style: {
                            width: '600px', // Set your desired width
                            height: '335px',
                            borderRadius: '10px'
                            // Set your desired height
                        },
                    }} open={this.state.openItem} onClose={this.handleCloseDialog} >
                        <DialogTitle sx={{
                            fontFamily: 'Montserrat,sans-serif',
                            fontSize: "22px",
                            fontWeight: 700,
                            lineHeight: "28px",
                            textAlign: 'left',
                            color: '#001C55',
                            padding: '20px 35px',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton sx={{ backgroundColor: '#E0F2F8', marginRight: '20px' }}>
                                    <img src={warning} />

                                </IconButton>
                                Remove item?
                            </Box>
                            <IconButton
                                onClick={this.handleCloseDialog}
                                sx={{
                                    position: 'absolute',
                                    right: '10px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                <CloseIcon sx={{ fontSize: '20px', color: '#94A3B8' }} />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography sx={{
                                fontFamily: 'Montserrat,sans-serif',
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                textAlign: 'left',
                                color: '#0F172A',
                                padding: '5px 15px'
                            }}>This action will delete the item and all associated tests, but you can
                                always add it again later if needed. Do you want to proceed?</Typography>

                            <FormControlLabel sx={{
                                padding: '20px 10px',

                            }}
                                control={
                                    <Checkbox
                                        checked={this.state.checked}
                                        onChange={this.handleChange}
                                        name="myCheckbox"
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            color: '#0F172A',
                                        }}
                                    >
                                        Don't show this again
                                    </Typography>
                                }
                            />
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                type="button"
                                onClick={this.handleConfirmDeleteItem}
                                sx={{ ...webStyle.buttonStyle }} // Make button full width
                                data-test-id="LoginBtn"
                                variant="contained"
                            >
                                Yes, remove item
                            </Button>
                            <Button
                                type="button"
                                onClick={this.handleConfirmDelete}
                                sx={{
                                    ...webStyle.cancelbuttonStyle,
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: '#0074BF',
                                    }
                                }}
                                data-test-id="LoginBtn"
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>}
                </Grid>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: formValues.addedAirCraftTypes.length > 0 ? 'center' : 'start' }}>
                    <Button
                        type="button"
                        disabled={!this.isFormValid()}
                        onClick={onSubmit}
                        sx={{
                            ...webStyle.buttonStyle, width: '100%',
                            maxWidth: formValues.class.length > 0 ? '300px' : '100%',
                            '&.Mui-disabled': {
                                backgroundColor: '#94A3B8',
                                color: '#F8FAFC'
                            },
                        }}
                        data-test-id="LoginBtn"
                        variant="contained"
                    >
                        Continue
                    </Button>
                </Box>

                <Typography variant="h6" align="center" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#0F172A",
                    display: "inline"
                }}>
                    <Link to="/Login" style={{
                        fontFamily: 'Montserrat, sans-serif',
                        display: "inline",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#0074BF",
                        textDecoration: "none",
                        marginLeft: "7px"
                    }}>
                        I’ll finish this later
                    </Link>
                </Typography>
            </Box>
        );
    }
}

const webStyle = {
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px',
    },
    inputWrapper: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1,
    },
    label: {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "3px",
        color: "#334155",
    },
    buttonStyle: {
        height: "50px",
        marginTop: "25px",
        width: "270px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none",
    },
    cancelbuttonStyle: {
        height: "50px",
        marginTop: "25px",
        width: "270px",
        marginBottom: "20px",
        borderRadius: "20px",
        cursor: "pointer",
        fontWeight: 700,
        color: '#0074BF',
        backgroundColor: "white",
        border: "1px solid #0074BF",
        textTransform: "none",
    },
    selectedOptions: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#94A3B8',
        margin: '7px 0px'
    }
};

export default AccountSetupStep2;
