export const userProfile = require("../assets/user-profile.png");
export const appLogo = require("../assets/logo.svg").default;
export const notifications = require("../assets/notification.svg").default;
export const settings = require("../assets/settings.svg").default;
export const calender = require("../assets/calendar.svg").default;
export const mail = require("../assets/mail.svg").default;
export const listIcon = require("../assets/list.svg").default;
export const lockIcon = require("../assets/lock.svg").default;
export const flyingFlight = require("../assets/flyingFlight.svg").default;
export const termsIcon = require("../assets/terms.svg").default;
export const privacyIcon = require("../assets/privacy.svg").default;
export const testSettingIcon = require("../assets/testSettingsIcon.svg").default;
export const contactUsIcon = require("../assets/contactUsIcon.svg").default;
export const logoutIcon = require("../assets/logoutIcon.svg").default;
export const aircraftTypesIcon = require("../assets/aircraftTypesIcon.svg").default;
export const airportSettingsIcon = require("../assets/airportSettingsIcon.svg").default;





