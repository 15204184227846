import React from "react";
import {
    Typography,
    IconButton,
    Dialog, DialogTitle, DialogContent, DialogContentText
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const textStyles = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'justify',
    color: '#0F172A'
};

const PrivacyPolicyModal = ({ open, onClose }: any) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth PaperProps={{
            style: { borderRadius: 16 }
        }} >
            <DialogTitle sx={{
                borderBottom: "1px solid #E2E8F0",
                fontSize: '22px',
                fontWeight: 700,
                lineHeight: '32px',
                textAlign: 'left',
                color: "#001C55"
            }}>
                Privacy Policy
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
            <CloseIcon sx={{ width: "18px", height: "18px", color: "#0074BF" }} />
            </IconButton>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 4 }}>
                <DialogContentText>
                    <Typography paragraph sx={textStyles}>
                        A privacy policy explains how your website, products, or services collect, use, and protect users' personal information. It details what data is gathered, how it’s used, and how it’s safeguarded, ensuring transparency and building trust with your users.                    </Typography>
                    <Typography paragraph sx={textStyles}>
                        Also known as a data protection policy or privacy statement, the purpose of this document is to inform users about their rights and how their data will be handled.                     </Typography>
                    <Typography paragraph sx={textStyles}>
                        Adhering to a privacy policy is essential for complying with data protection laws and regulations.
                    </Typography>

                    <Typography paragraph sx={textStyles}>
                        By clearly outlining your data practices, a privacy policy helps users understand what to expect when they engage with your website or services. It empowers users by informing them of their rights, including the right to access, correct, or delete their personal information. Additionally, it establishes your commitment to protecting user privacy, which is crucial for fostering a positive relationship with your audience. Implementing a robust privacy policy not only mitigates the risk of data breaches and legal issues but also enhances your organization’s credibility and reputation in the eyes of your users.                    </Typography>
                    {/* Add more content as needed */}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default PrivacyPolicyModal;
