// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation, Routes, Route } from 'react-router-dom';

import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';

import Login from "../../blocks/email-account-login/src/Login.web";
import Startup from "../../blocks/splashscreen/src/Startup";
import Signup from "../../blocks/email-account-registration/src/Signup.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import AccountStatus from "../../blocks/forgot-password/src/AccountStatus.web";
import AccountSetup from "../../blocks/email-account-registration/src/AccounSetUp.web";
import SideBar from "../../blocks/email-account-login/src/SideBar.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import SettingChangePassword from "../../blocks/settings2/src/SettingChangePassword.web"

import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";


import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";

import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";

import Notifications from "../../blocks/notifications/src/Notifications";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Search from "../../blocks/search/src/Search";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import PrivacyAndPolicy from "../../blocks/termsconditions/src/PrivacyAndPolicy.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const routeMap = {
  Startup: {
    component: Startup,
    path: "/"
  },
  Login: {
    component: Login,
    path: "/Login"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/forgot-password"
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/reset-password"
  },
  Signup: {
    component: Signup,
    path: "/Signup"
  },
  AccountStatus: {
    component: AccountStatus,
    path: "/account-status"
  },
  AccountSetup: {
    component: AccountSetup,
    path: "/account-setup"
  },
  SideBarLayout: {
    component: SideBar,
    path: "/sidebar-layout"
  },
  Profile:{
    component: CustomisableUserProfiles,
    path: "/Profile",
    isWrapper:true
  },
  ChangePassword:{
    component: SettingChangePassword,
    path: "/change-password",
    isWrapper:true
  },
  TermsConditions:{
    component :TermsConditions,
    path:"/terms-conditions",
    isWrapper:true
  },
  PrivacyPolicy:{
    component :PrivacyAndPolicy,
    path:"/privacy-policy",
    isWrapper:true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <ToastContainer />
      <WebRoutesGenerator routeMap={routeMap} />
    </BuilderProvider>
  );
}

export default App;