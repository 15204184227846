import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikTouched, FormikErrors } from "formik";
import React from "react";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  isFormSubmitted: boolean;
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  value: string;
  label: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendLinkApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      password: "",
      email: "",
      isFormSubmitted:false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (responseJson.errors) {
          toast.error(responseJson.errors[0].failed_login);
        } else if (apiRequestCallId === this.sendLinkApiCallId) {
          localStorage.setItem("accessToken", responseJson.meta.token);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      touched[value as keyof object] &&
      errors[value as keyof object] && (
        <div
          style={{
            fontSize: "12px",
            fontWeight: 300,
            color: "#f94b4b",
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleSendRecoveryLink = () => {
    this.setState({isFormSubmitted: true})
   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     
    const headers = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: {
        attributes: {
          email: this.state.email,
        },
      },
    };

    this.sendLinkApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/send_link"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),"POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  };

  // Customizable Area End
}
