import React from "react";
import { Drawer, styled } from "@mui/material";
import SettingSideNavigation from "./SettingSideNavigation";

interface SettingSideNavigationProps {
  navigateTo: (path: string) => void;
  open: boolean;
  handleClose: () => void;
}
const MobileSideNavigation: React.FC<SettingSideNavigationProps> = ({
  navigateTo,
  open,
  handleClose,
}) => {
  return (
    <>
      <StyledDrawer anchor="left" open={open} onClose={handleClose}>
        <SettingSideNavigation
          navigateTo={navigateTo}
          onClose={handleClose}
        />
      </StyledDrawer>
    </>
  );
};

export default MobileSideNavigation;

const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    width: "100%",
    maxWidth: "350px",
    boxSizing: "border-box",
    overflowX: "visible",
    overflowY:"auto"
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "transparent !important",
    zIndex: "0 !important",
    width: "100%",
  },
  "& .css-1f31srq": {
    padding: "0px",
  },
  "@media (min-width: 900px)": {
    display:"none"
  }
});
