// Customizable Area Start
import React from "react";
import { Box, styled, Typography } from "@mui/material";

import PrivacyAndPolicyController, {
  Props,
} from "./PrivacyAndPolicyController";

export default class PrivacyAndPolicy extends PrivacyAndPolicyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <StyledPrivacyAndPolicyContainer>
          <Box className="privacyPolicyHeader">Privacy policy</Box>
          <Box className="privacyPolicyContentContainer">
            <Box className="privacyPolicyContents">
              <Typography className="content">
                To review our privacy policy, please visit
              </Typography>
              <a
                className="content"
                href="https://www.checkridepro.app/privacy."
                target="_blank"
                style={{color:"#40c3f3"}}
              >
                https://www.checkridepro.app/privacy.
              </a>
            </Box>
          </Box>
        </StyledPrivacyAndPolicyContainer>
      </>
    );
  }
}

const StyledPrivacyAndPolicyContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& .privacyPolicyHeader": {
    display: "flex",
    height: "80px",
    paddingLeft: "50px",
    alignItems: "center",
    background: "#ffffff",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    color: "#0074BF",
  },
  "& .privacyPolicyContentContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    "& .privacyPolicyContents": {
      background: "#ffffff",
      borderRadius: "20px",
      display: "flex",
      width: "clamp(300px,50vw, 820px)",
      padding: "40px",
      gap: "20px",
      flexDirection: "column",
      "& .content": {
        fontFamily: "Montserrat",
        textALign: "justified",
        fontWeight: 400,
        fontSize: "16px",
      },
    },
  },
  "@media (max-width: 900px)": {
    "& .privacyPolicyContentContainer": {
      "& .privacyPolicyContents": {
        width: "clamp(300px,80vw, 860px)",
      },
    },
  },
  "@media (max-width: 600px)": {
    "& .privacyPolicyContentContainer": {
      padding: "20px",
      "& .privacyPolicyContents": {
        width: "clamp(224px,75vw, 560px)",
        padding: "30px",
      },
    },
  },
});

// Customizable Area End
