import React from "react";
import { Box, Divider, Typography, styled } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
interface NavigationButtonProps {
  label: string;
  onClick: () => void;
  icon: string;
  isActive?: boolean;
}
export const NavigationButton: React.FC<NavigationButtonProps> = ({
  label,
  onClick,
  icon,
  isActive = false,
}) => {
  return (
    <StyledNavButton onClick={onClick}>
      <Box sx={webStyle.navButtons(isActive)}>
        <Box sx={webStyle.navIconLabel}>
          <img src={icon} height={"24px"} width={"24px"} style={{ marginLeft:"16px"}} />
          <Typography sx={webStyle.navLabel}>{label}</Typography>
        </Box>
        <NavigateNextIcon sx={{ color: "#0074BF", marginRight:"18px" }} />
      </Box>
      <Divider className="divider" />
    </StyledNavButton>
  );
};


const StyledNavButton = styled(Box)({
  "& .divider": {
    display: "none",
  },
  "@media (max-width: 900px)": {
    "& .divider": {
      display: "block",
    },
  },
});

const webStyle = {
  navButtons:  (isActive: boolean) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px 0x 20px",
    cursor: "pointer",
    marginLeft: "10px",
    marginTop:"5px",
    marginRight: "10px",
    borderRadius:"20px",
    height:"46px",
    backgroundColor: isActive ? "#E0F2F8" : "transparent",
    
  }),
  navIconLabel: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  navLabel: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    color: "#0F172A",
  },
};
