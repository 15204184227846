import React from "react";
import {
    Container,
    Box,
    Typography,
    Button,
} from "@mui/material";
import { Link } from 'react-router-dom';

import { loginLogo, micImage, schoolImage } from "./assets";


// Customizable Area Start
import StartupController, { Props } from "./StartupController";
import { grpImage,headeSetMicActiveIcon,schoolIcon } from "./assets";
import { leftArrow } from "./../../email-account-registration/src/assets";
// Customizable Area End

export default class Startup extends StartupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <Container
                maxWidth={false} sx={{
                    backgroundColor: "#F1F5F9",
                    minHeight: '100vh',
                    width: '100%',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${grpImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.04,
                        zIndex: 1,
                        width: '100%',
                        minHeight: '100vh', // Use minHeight here too
                    }}
                />

                <Box
                    sx={{
                        position: 'relative',
                        zIndex: 2,
                        width: '100%',
                        minHeight: '100vh', // Use minHeight here too
                    }}
                >
                    {!this.state.clickTobutton && <Box sx={webStyle.mainWrapper}>
                        <img src={loginLogo} style={{ marginBottom: "50px" }} />
                        <Button
                            type="submit"
                            sx={webStyle.buttonStyle}
                            onClick={() => this.clickToLoginOrSignup('Signup')}
                            data-test-id="signupBtn"
                            variant="contained"
                        >
                            Create an account
                        </Button>

                        <Button
                            type="submit"
                            sx={webStyle.loginStyle}
                            data-test-id="LoginBtn"
                            onClick={this.navigateToLoginScreen}
                            variant="outlined"
                        >
                            Login
                        </Button>
                    </Box>}

                    {this.state.clickTobutton && <Box sx={webStyle.selectRoleWrapper}>
                        <span onClick={() => this.setState({ clickTobutton: false })
                        } style={{ position: "relative", top: '150px', right: "500px" }}>
                            <img src={leftArrow} style={{ margin: '7px 0px', height: '19px', width: '19px' }} alt="left-arrow" />
                        </span>
                        <img src={loginLogo} />
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Typography
                                data-test-id="labelBody"
                                sx={{ fontSize: "22px", fontWeight: 700, color: "#001C55", py: 3 }}
                            >
                                Tell us about your role:
                            </Typography>

                            <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} gap={'35px'}>
                                <Box
                                    data-testid="app-role-btn"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        color: '#000000',
                                        width: '166px',
                                        height: '166px',
                                        cursor: "pointer",
                                        borderRadius: '20px',
                                        backgroundColor: "ffffff",
                                        boxShadow: '0px 2px 4px 0px rgba(0, 28, 85, 0.1)',
                                        border: this.state.role == "applicant" ? "2px solid #5274B5" : "none",
                                    }}
                                    onClick={() => this.setRole('applicant')}
                                >
                                    <img src={this.state.role == "applicant"? schoolImage : schoolIcon} />

                                    <Typography
                                        data-test-id="labelBody"
                                        sx={{
                                            fontSize: "16px", fontWeight: 500, color:this.state.role == "applicant"? "#001C55":"#00000080",
                                            fontFamily: 'Montserrat, sans-serif',
                                        }}
                                    >
                                        I’m an Applicant
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        color: '#000000',
                                        width: '166px',
                                        height: '166px',
                                        cursor: "pointer",
                                        borderRadius: '20px',
                                        backgroundColor: "ffffff",
                                        border: this.state.role == "dpe" ? "2px solid #5274B5" : "none",
                                        boxShadow: '0px 2px 4px 0px rgba(0, 28, 85, 0.1)',
                                    }}
                                    onClick={() => this.setRole('dpe')}
                                    data-testid="dpe-role-btn"
                                >
                                    <img src={this.state.role == "dpe" ? headeSetMicActiveIcon : micImage} />
                                    <Typography
                                        data-test-id="labelBody"
                                        sx={{
                                            fontSize: "16px", fontWeight: 500, color: this.state.role == "dpe"?"#001C55":"#00000080",
                                            fontFamily: 'Montserrat, sans-serif',
                                        }}
                                    >
                                        I’m a DPE
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            <Typography
                                data-test-id="labelBody"
                                sx={{ fontSize: "16px", fontWeight: 400, color: "#000000" }}
                            >
                                Already have an account?
                                <Link
                                    to="/Login"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: "16px", color: "#0074BF", marginRight: "10px", fontWeight: 700,
                                        marginLeft: "3px"
                                    }}>
                                    Login</Link>

                            </Typography>
                        </Box>

                    </Box>}
                </Box>
            </Container>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    inputWrapper: {
        position: "relative",
    },
    mainWrapper: {
        paddingBottom: "30px",
        display: "flex",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw"
    },
    selectRoleWrapper: {
        display: "flex",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        height: "100vh",
        width: "100%"
    },
    buttonStyle: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none"
    },
    loginStyle: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        padding: '10px 20px',
        border: "2px solid #0074BF",
        backgroundColor: "#ffffff",
        color: "#0074BF",
        borderRadius: '20px',
        cursor: "pointer",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none"
    },
    label: {
        fontSize: "14px",
        fontWeight: 300,
        marginTop: "8px",
    },
    formWrapper: {
        boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        padding: "20px",
        width: "100%",
    },
};
// Customizable Area End