import React from 'react';
import { Box, Button, TextField, IconButton, InputLabel, Typography } from '@mui/material';
import MultipleDropDown from "./components/MultipleDropDown";
import { Link } from 'react-router-dom';
import { leftArrow, pdfIcon } from "./assets";

import { MoreVert } from '@mui/icons-material';
import UploadPdfButton from './components/UploadPdfButton'

interface AccountSetupStep3Props {
  formValues: any;
  onChangeItems: (name: string, value: string[]) => void;
  onInputHandleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handlePrevious: () => void
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void; // Define the type for onSubmit
  handleFileSelect: any
}
interface states {
  open: boolean,
  checked: boolean

}
class AccountSetupStep3 extends React.Component<AccountSetupStep3Props, states> {
  constructor(props: AccountSetupStep3Props) {
    super(props);
    this.state = {
      open: false,
      checked: false
    };
  }

  render() {
    const { formValues, onSubmit, handlePrevious, onInputHandleChange, handleFileSelect, onChangeItems } = this.props;
    return (
      <Box sx={webStyle.formWrapper}>
        <Box sx={webStyle.inputWrapper}>
          <Typography variant="h6" sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 700,
            fontSize: "22px",
            color: "#001C55",
          }}>
            <img src={leftArrow} style={{ padding: '7px', height: '21px', width: '24px' }} onClick={() => handlePrevious()} />
            Time for instructions!
          </Typography>

          <Typography variant="h6" sx={{
            margin: '15px 0px',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: "16px",
            color: "#0F172A",
          }}>
            Add the final details your applicants need for each designation:
          </Typography>

        </Box>
        <Box sx={{ ...webStyle.inputWrapper }}>
          <InputLabel style={webStyle.label}>Designation</InputLabel>
          <MultipleDropDown uploadedData={formValues.uploadedData} options={formValues?.designationOption} name='designation' placeholder="Enter aircraft type" selectedItems={formValues.designation} onItemsChange={onChangeItems} />
        </Box>
        <Box sx={{
          width: "100%", display: 'flex', justifyContent: 'space-between'

        }}>
          <Box sx={{
            width: '300px'
          }}>
            <InputLabel style={webStyle.label}>Exam cost</InputLabel>
            <TextField
              data-test-id={"exam cost"}
              name="examCost"
              id="examCost"
              value={formValues.examCost}
              onChange={onInputHandleChange}
              size="medium"
              fullWidth={true}
              variant="outlined"
              type="text"
              placeholder="e.g., $1,000.00"
              sx={{
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #CBD5E1",
                    borderWidth: "1px",
                  },
                },
                backgroundColor: "#ffffff",
                color: "#94A3B8",
                mb: "3px",
              }}

              InputProps={{
                style: {
                  border: "1px solid #CBD5E1",
                  borderRadius: '20px',
                  fontWeight: 400,

                }
              }}
            />

          </Box>
          <Box sx={{
            width: '300px',
            marginLeft: '20px'
          }}>
            <InputLabel style={webStyle.label}>Accepted payment methods</InputLabel>
            <TextField
              id="paymentMethods"
              data-test-id={"paymentMethods"}
              value={formValues.paymentMethods}
              onChange={onInputHandleChange}
              size="medium"
              name="paymentMethods"
              variant="outlined"
              placeholder="e.g., Credit Card, PayPal, Cash"
              fullWidth={true}
              type={"text"}
              sx={{
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #CBD5E1",
                    borderWidth: "1px",
                  },
                },
                backgroundColor: "#ffffff",
                color: "#94A3B8",
                mb: "3px",
              }}

              InputProps={{
                style: {
                  border: "1px solid #CBD5E1",
                  borderRadius: '20px',
                  fontWeight: 400
                }
              }}
            />
          </Box>
        </Box>

        <Box sx={webStyle.inputWrapper}>
          <InputLabel style={webStyle.label}>Instructions</InputLabel>
          <TextField
            data-test-id={"instructions"}
            name="instructions"
            rows={7}
            multiline
            value={formValues.instructions}
            onChange={onInputHandleChange}
            size="medium"
            fullWidth={true}
            variant="outlined"
            type="text"
            placeholder="Enter detailed instructions or guidelines for the designation here"
            sx={{
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #CBD5E1",
                  borderWidth: "1px",
                },
              },
              backgroundColor: "#ffffff",
              color: "#94A3B8",
              mb: "3px",
            }}

            InputProps={{
              style: {
                border: "1px solid #CBD5E1",
                borderRadius: '20px',
                fontWeight: 400,
              }
            }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box sx={webStyle.fileList}>
            {formValues?.uploadedFiles?.length > 0 && formValues?.uploadedFiles.map((file: any, index: any) =>  (
                <Box key={index} sx={webStyle.fileItem}>
                  <Box sx={webStyle.fileIcon}>
                    <img style={{
                    }} src={pdfIcon} width={'16px'} height={'16px'} />
                  </Box>
                  <Box sx={webStyle.fileInfo}>
                    <Typography sx={webStyle.fileName}>{file.name}</Typography>
                    <Typography sx={webStyle.fileSize}>{file.size}</Typography>
                  </Box>
                  <IconButton size="small">
                    <MoreVert sx={{ color: '#94A3B8', fontSize: 20 }} />
                  </IconButton>
                </Box>
              )
            )}
          </Box>
          <Box sx={{ justifySelf: 'end' }}>
            <UploadPdfButton data-test-id="upload-pdf-button" onFileSelect={(event) => handleFileSelect(event)} />
          </Box>
        </Box>
        {/* </Box> */}


        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            type="button"
            onClick={onSubmit}
            sx={{ ...webStyle.buttonStyle, width: '100%', maxWidth: '300px' }} // Make button full width
            data-test-id="LoginBtn"
            variant="contained"
          >
            Continue
          </Button>
        </Box>

        <Typography variant="h6" align="center" sx={{
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: 400,
          fontSize: "16px",
          color: "#0F172A",
          display: "inline"
        }}>
          <Link to="/Login" style={{
            fontFamily: 'Montserrat, sans-serif',
            display: "inline",
            fontWeight: 700,
            fontSize: "16px",
            color: "#0074BF",
            textDecoration: "none",
            marginLeft: "7px"
          }}>
            I’ll finish this later
          </Link>
        </Typography>
      </Box>
    );
  }
}

const webStyle = {
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: '26px',
    border: '1px solid rgb(252, 250, 250)',
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '30px',
    // width: '940px',
    // height: '880px'

  },
  inputWrapper: {
    position: "relative",
    width: "100%",
    // display: 'flex',
    // flexDirection: 'row',
    mb: 1,
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "3px",
    color: "#334155",
  },
  buttonStyle: {
    height: "50px",
    marginTop: "25px",
    marginBottom: "20px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none",
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'flex-start',
    width: '100%',
  },
  fileList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    border: '1px solid #F1F5F9',
    padding: '8px',
    borderRadius: '20px',
    backgroundColor: '#F8FAFC',
    width: '210px',
  },
  fileIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    backgroundColor: '#E0F2F8',
    height: '30px',
    borderRadius: '8px',
    marginRight: '15px',

  },
  fileInfo: {
    flex: 1,
  },
  fileName: {
    fontSize: '14px',
    color: '#334155',
    fontWeight: 500,
  },
  fileSize: {
    fontSize: '12px',
    color: '#64748B',
  },
  uploadButton: {
    padding: '8px 16px',
    borderRadius: '8px',
    backgroundColor: '#E0F2FE',
    color: '#0EA5E9',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#BAE6FD',
    },
  },

};

export default AccountSetupStep3;
