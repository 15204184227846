// HorizontalStepper.tsx
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface StepperProps {
  activeStep: number;
  totalSteps: number;
  children: ReactNode;
}

const HorizontalStepper: React.FC<StepperProps> = ({ activeStep, totalSteps, children }) => {
  return (
    <Box sx={styles.container}>
      {/* Horizontal stepper bars */}
      <Box sx={styles.stepperContainer}>
        {[...Array(totalSteps)].map((_, index) => (
          <Box 
            key={index}
            sx={{
              ...styles.stepBar,
              backgroundColor: index <= activeStep ? '#5274B5' : '#F8FAFC'
            }}
          />
        ))}
      </Box>

      {/* Content area */}
      <Box sx={styles.contentArea}>
        {React.Children.toArray(children)[activeStep]}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: '100%',
    padding: '16px',
    margin: '0 auto',
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
  
  },
  stepperContainer: {
    display: 'flex',
    gap: '16px',
    marginBottom: '24px',
    marginTop:'10px',
    width: '100%',
    justifyContent: 'center', 
  },
  stepBar: {
    height: '8px',
    width: '100px',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
  },
  contentArea: {
    marginBottom: '24px',
    overflow: 'auto' ,
    width: '100%',
    display: 'flex',     // Add this
    justifyContent: 'center', // Add 
  },
} as const;

export default HorizontalStepper;