import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  clickTobutton: boolean;
  role: string;
  actionType: string;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StartupController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
  // Customizable Area Start
    this.state = {
      clickTobutton: false,
      role: "",
      actionType: ""
    };

    this.subScribedMessages = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {

  }

  async componentDidMount() {
    super.componentDidMount();
    let selected = new URLSearchParams(window.location.search).get('selected')
    if (selected) {
      this.setState({ clickTobutton: true , actionType:'Signup', role: selected})
    }
  }

  clickToLoginOrSignup(actionType: string) {
    this.setState({ clickTobutton: true, actionType: actionType })
  }

  setRole(role: string) {
    this.setState(
      (prevState) => ({
        ...prevState, 
        role: role    
      }),
      () => {
        this.props.navigation.navigate(`${this.state.actionType}`,{role:role});
      }
    );
  }

  navigateToLoginScreen = () => {
    this.props.navigation.navigate("Login");  
  };
  // Customizable Area End
}

