import React from 'react';
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Divider,
  TextField
} from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

import AppointmentsController, {
  Props,
  configJSON,
} from './AppointmentsController';
import { TimeSlot } from './types';

export default class Appointments extends AppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWebList(item: TimeSlot) {
    return (
      <Box key={`web-item-${item.sno}`} sx={webStyle.tableBox}>
        <Typography variant="h6">
          {`${configJSON.slot}:  ${item.from} - ${item.to}`}
        </Typography>
      </Box>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { appointmentsList } = this.state;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: '1rem 0',
              }}
            >
              <Button
                data-test-id="btnNavigateToAddAppointments"
                variant="contained"
                color="primary"
                onClick={() => this.navigateToAddAppointment()}
              >
                {configJSON.addAppointment}
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                margin: '1rem 0',
              }}
            >
              <Typography noWrap variant="h6">
                {configJSON.availableDate}:{' '}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  data-test-id="txtInputAvailableDate"
                  //@ts-ignore #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
                  inputFormat={configJSON.webFormat}
                  minDate={configJSON.minDate}
                  maxDate={configJSON.maxDate}
                  value={this.state.available_date}
                  onChange={(value) =>
                    this.update({ available_date: this.toMomentDate(value) })
                  }
                  //@ts-ignore #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <Divider
                orientation="horizontal"
                flexItem
                style={{ margin: '1rem 0' }}
              />

              <Button
                data-test-id="btnGetAppointment"
                variant="contained"
                color="primary"
                onClick={() => this.getAppointmentList(this.state.token)}
                disabled={!this.state.token}
              >
                {configJSON.getList}
              </Button>
            </Box>

            <Box
              sx={webStyle.appointmentContainer}
              data-test-id={"appointmentsList"}
            >
              {appointmentsList.map((item: TimeSlot) => this.getWebList(item))}
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  appointmentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    backgroundColor: 'white',
    marginTop: '20px',
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#ccc',
    padding: 15,
    marginVertical: 10,
  },
};
// Customizable Area End
