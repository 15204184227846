import React from "react";

// Customizable Area Start
import { Image } from "react-native";
import ICalendarLink from "react-icalendar-link";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Typography, Button, Popover } from "@builder/component-library";
import { deleteIcon } from "./assets";
// Customizable Area End

import ICalendarSupportController, {
  Props,
  configJSON,
} from "./ICalendarSupportController";
import { View, StyleSheet, Pressable } from "react-native";

// Customizable Area Start

// Customizable Area End

export default class ICalendarSupport extends ICalendarSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <View style={{ alignItems: "center" }}>
          <View
            style={[
              { width: window.innerWidth > 700 ? 600 : "max-content" },
              webStyle.mainWrapper,
            ]}
          >
            <Button
              text={configJSON.import}
              style={webStyle.buttonStyle}
              input={{
                type: 'file',
                onFileChange: (uri?: string) => {
                  uri && this.readICSFile(uri);
                },
                accepts: 'text/calendar',
              }}
              data-test-id="file-import"
            />
            <View style={{ width: "100%" }}>
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                data-test-id="eventCalendar"
                eventContent={(eventInfo) => {
                  return (
                    <Pressable
                      style={webStyle.event}
                      data-test-id="eventItem"
                      onPress={() => this.eventClickHandlerWeb(eventInfo)}
                    >
                      <Typography
                        variant="lg"
                        style={webStyle.eventTitle}
                        component="h2"
                      >
                        {eventInfo.event.title}
                      </Typography>
                    </Pressable>
                  );
                }}
                events={this.state.events}
              />
            </View>
          </View>
        </View>
        <Popover
          isVisible={!!this.state.selectedEvent}
          onPressCloseIcon={this.closeModalHandle}
          data-test-id="eventModal"
          title={this.state.selectedEvent?.event?.title}
          containerStyle={{ width: "fit-content" }}
          description={
            <>
              <Typography style={webStyle.description}>
                {this.state.selectedEvent?.event?.extendedProps?.description}
              </Typography>
              <Typography style={webStyle.date}>
                {configJSON.date}{" "}
                {this.getDate(new Date(this.state.selectedEvent?.event.start))}
              </Typography>
            </>
          }
        >
          {/* columnGap doesnt exist on RN 0.64*/}
          <View style={{ ...webStyle.buttonContainer }}>
            <Button
              style={webStyle.actionButton}
              data-test-id="deleteEvent"
              onPress={() => {
                this.deleteEvent(this.state.selectedEvent?.event?.id);
                this.closeModalHandle();
              }}
              icon={<Image source={deleteIcon} style={webStyle.deleteImage} />}
              text={configJSON.delete}
            />
            <Button
              style={webStyle.actionButton}
              onPress={this.closeModalHandle}
              text={configJSON.cancel}
            />
            {            //@ts-expect-error #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
            } <ICalendarLink
              event={{
                title: this.state.selectedEvent?.event.title,
                description:
                  this.state.selectedEvent?.event?.extendedProps?.description,
                startTime: this.state.selectedEvent?.event.start,
                location:
                  this.state.selectedEvent?.event?.extendedProps?.location,
              }}
            >
              <Button style={webStyle.actionButton} text={configJSON.export} />
            </ICalendarLink>
          </View>
        </Popover>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#fff",
  },

  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    // border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  event: {
    backgroundColor: "#3f51b5",
    padding: 5,
    borderRadius: 4,
    width: "100%",
    overflow: "hidden",
    alignItems: "center",
  },
  eventTitle: {
    color: "#fff",
    fontSize: 14,
    marginVertical: 0,
  },
  modalEventTitle: {},
  description: {
    color: "#808080",
    marginTop: 10,
    marginBottom: 0,
  },
  date: {
    color: "#808080",
    marginTop: 0,
    marginBottom: 0,
  },
  iCalenderLink: {
    // textDecoration: "none",
  },
  actionButton: {
    backgroundColor: "#3f51b5",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
    justifyContent: "flex-end",
  },
  deleteImage: {
    width: 24,
    height: 24,
  },
});
// Customizable Area End
