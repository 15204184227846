import React from "react";
import {
    Grid, Box, Typography, Drawer, MenuList, MenuItem
} from "@mui/material";

import SideBarController, { Props } from "./SideBarController.web";
import {
    appLogo,
    calander,
    settings,
    notifications,
    mail, listIcon,
    rightArrowIcon,

} from './assets'

export default class SideBar extends SideBarController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { settingsMenuOpen, settingMenu } = this.state
        return (
            <>
                <Grid container direction={'row'} xs={12} sx={{
                    width: '100%',
                    height: '100%',

                }}>
                    <Drawer
                        anchor="left"
                        open={settingsMenuOpen}
                        sx={{
                            flexShrink: 0,
                            padding: 0,
                            '& .MuiDrawer-paper': {
                                width: 480,
                                // left: '120px',
                                boxSizing: 'border-box',
                                backgroundColor: 'none',
                                boxShadow: 'none',
                                zIndex: 0,
                                overflowY: 'hidden',
                                height: '100%'
                            },
                            '& .MuiBackdrop-root': {
                                backgroundColor: 'transparent !important',
                                zIndex: '0 !important'
                            },
                        }}
                    >
                        <Grid sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                            <Grid sx={webStyle.menuIconContainer}>
                                <Grid sx={{ padding: '20px' }}>
                                    <img src={appLogo.default} style={{ height: '100%', width: '100%' }} />
                                </Grid>
                                <Grid sx={webStyle.middleContainer}>
                                    <Grid sx={webStyle.iconContainer}>
                                        <img src={calander.default} style={{ filter: 'invert(50%)', height: '45%', width: '45%' }} />
                                        <Typography
                                            sx={{
                                                ...webStyle.iconLabel,
                                                color: '#94A3B8'
                                            }}>
                                            Calendar
                                        </Typography>
                                    </Grid>
                                    <Grid sx={webStyle.iconContainer}>
                                        <img src={listIcon.default} style={{ filter: 'invert(50%)', height: '45%', width: '45%' }} />
                                        <Typography
                                            sx={{
                                                ...webStyle.iconLabel,
                                                color: '#94A3B8'
                                            }}>
                                            Checklist
                                        </Typography>
                                    </Grid>
                                    <Grid sx={webStyle.iconContainer}>
                                        <img src={notifications.default} style={{ filter: 'invert(50%)', height: '40%', width: '40%' }} />
                                        <Typography
                                            sx={{
                                                ...webStyle.iconLabel,
                                                color: '#94A3B8'
                                            }}>
                                            Notifications
                                        </Typography>
                                    </Grid>
                                    <Grid sx={webStyle.iconContainer}>
                                        <img src={mail.default} style={{ filter: 'invert(50%)', height: '48%', width: '45%' }} />
                                        <Typography
                                            sx={{
                                                ...webStyle.iconLabel,
                                                color: '#94A3B8'
                                            }}>
                                            Messages
                                        </Typography>
                                    </Grid>
                                    <Grid sx={webStyle.iconContainer}>
                                        <img src={settings.default} style={{
                                            filter: 'invert(10%)' , height: '45%', width: '45%', padding: '10px'
                                        }} />
                                        <Typography
                                            sx={{
                                                ...webStyle.iconLabel,
                                                color: '#0074BF' 
                                            }}>
                                            Settings
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid sx={webStyle.iconContainer}>
                                    <Typography sx={{
                                        color: '#0074BF',
                                        backgroundColor: '#E0F2F8',
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        fontFamily: 'Montserrat, sans-serif',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>JD</Typography>
                                    <Typography sx={webStyle.iconLabel}>Profile</Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{
                                padding: 2,
                                overflowY: 'auto !important',
                                height: '100%',
                                border: '0.1px solid #E2E8F0',

                            }}>
                                <Typography sx={webStyle.title} variant="h6">Settings</Typography>
                                <Box sx={{
                                    ...webStyle.profileCard, backgroundColor: '#E0F2F8',
                                    margin: '35px 0px'
                                }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            sx={{
                                                color: '#0074BF',
                                                backgroundColor: '#E0F2F8',
                                                width: '40px',
                                                height: '40px',
                                                borderRadius: '50%',
                                                fontFamily: 'Montserrat, sans-serif',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #0074BF',
                                                marginRight: '20px',
                                            }}
                                        >
                                            JD
                                        </Typography>
                                        <Box>
                                            <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: '16px', fontWeight: 500, lineHeight: '26px' }}>John Doe</Typography>
                                            <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: '16px', fontWeight: 400, lineHeight: '22px', color: '#334155' }}>
                                                Edit profile
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <img src={rightArrowIcon.default} style={{ height: '100%', width: '100%' }} />
                                    </Box>
                                </Box>

                                <MenuList>
                                    <Typography sx={webStyle.menuHeading}>ACCOUNT</Typography>
                                    {settingMenu.slice(0, 4).map((item: any, index: any) => (
                                        <MenuItem key={index} sx={webStyle.profileCard} >
                                            <Box sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}>
                                                <img
                                                    style={{ width: '100%', height: '100%' }}
                                                    src={item.icon}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        lineHeight: '26px',
                                                        fontSize: '16px',
                                                        fontWeight: 500,
                                                        marginLeft: '20px'
                                                    }}>
                                                    {item.label}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <img
                                                    style={{
                                                        height: '100%',
                                                        width: '100%'
                                                    }}
                                                    src={rightArrowIcon.default} />
                                            </Box>
                                        </MenuItem>
                                    ))}
                                    <Typography sx={webStyle.menuHeading}>
                                        SUPPORT
                                        </Typography>
                                    {settingMenu.slice(4).map((item: any, index: any) => (
                                        <MenuItem key={index + 4} sx={webStyle.profileCard}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={item.icon} style={{ height: '100%', width: '100%' }} />
                                                <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: '16px', fontWeight: 500, lineHeight: '26px', marginLeft: '20px' }}>
                                                    {item.label}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <img src={rightArrowIcon.default} style={{ height: '100%', width: '100%' }} />
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Box>
                        </Grid>

                    </Drawer>
                </Grid >
            </>
        );
    }
}

const webStyle = {
    menuIconContainer: {
        width: '120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // heigt: '100vh'
        position: 'relative', // Important to ensure icons are above other elements
        zIndex: 1500,
    },
    middleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    menu: {
        paddingTop: '20px',
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    iconLabel: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '14.63px',
        letterSpacing: '-0.24px',
        textAlign: 'center',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
        cursor: 'pointer',
        pointerEvents: 'auto',
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#0074BF',
        marginLeft: '20px'
    },
    profileCard: {
        height: '73px',
        padding: '6px 30px',
        gap: '20px',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    menuHeading: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#94A3B8'
    }

};
