export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");

export const loginLogo = require("../assets/login-logo.png");
export const loginImage = require("../assets/group_Signup_card.png");

export const mail = require("../assets/mail.svg");
export const listIcon = require("../assets/list.svg");
export const rightArrowIcon = require("../assets/rightArrow.svg");
export const lockIcon = require("../assets/lock.svg");
export const flyingFlight = require("../assets/flyingFlight.svg");
export const rightFlight = require("../assets/rightFlight.svg");
export const cardFlight = require("../assets/cardFlight.svg");
export const termsIcon = require("../assets/terms.svg");
export const phoneIcon = require("../assets/phone.svg");
export const privacyIcon = require("../assets/privacy.svg");
export const appLogo = require("../assets/logo.svg");
export const notifications = require("../assets/notification.svg");

export const settings = require("../assets/settings.svg");
export const calander = require("../assets/calendar.svg");