import React, {useState} from "react";
import { Box, Divider, Typography, styled } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { lockIcon,
         privacyIcon,
         termsIcon,
         testSettingIcon,
         aircraftTypesIcon,
         contactUsIcon,
         airportSettingsIcon,
         logoutIcon 
        } from "../assets";
import { NavigationButton } from "./CommonComponents";


interface SettingSideNavigationDPEProps {
  navigateTo: (path: string) => void;
  onClose?: () => void;
}
const SettingSideNavigation: React.FC<SettingSideNavigationDPEProps> = ({
  navigateTo,
  onClose,
}) => {

  const [activeItem, setActiveItem] = useState<string>("");

  const handleNavigate = (path: string) => {
    setActiveItem(path);
    navigateTo(path);
    if (onClose) {
      onClose();
    }
  };
  const accountNav = [
    {
      icon: lockIcon,
      to: "ChangePassword",
      label: "Change Password",
    },
    {
      icon: airportSettingsIcon,
      to: "Login",
      label: "Airport settings",
    },
    {
      icon: testSettingIcon,
      to: "Login",
      label: "Test settings",
    },
    {
      icon: aircraftTypesIcon,
      to: "Login",
      label: "Aircraft types",
    },
  ];
  const supportNav = [
    {
      icon: termsIcon,
      to: "TermsConditions",
      label: "Terms and conditions",
    },
    {
      icon: privacyIcon,
      to: "PrivacyPolicy",
      label: "Privacy policy",
    },
    {
    icon: contactUsIcon,
    to: "Login",
    label: "Contact us",
    },
  ];
  return (
    <StyledSettingNavigationContainer>
      <Box className="navigationHeading">
        <Typography sx={webStyle.navigationTabHeading}>Settings</Typography>
        <NavigateBeforeIcon className="closeDrawer" onClick={onClose} />
      </Box>
      <Box
          onClick={() => {
            handleNavigate("Profile");
          }}
          sx={{
            backgroundColor: activeItem === "Profile" ? "#E0F2F8" : "transparent", 
          }}
        className="profileButton"
        data-testid="profileNavigation"
       
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box sx={webStyle.profileNameLogoContainer(activeItem)}>
            <Typography 
            sx={webStyle.profileNameLogo}
            > 
            JD 
            </Typography> .
          </Box >

          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={webStyle.userName}>
              John Doe
              </Typography>
            <Typography 
            sx={webStyle.editProfileLabel}
            >
              Edit profile
            </Typography>
          </Box>  </Box>
        <NavigateNextIcon 
        sx={{
           color: "#0074BF"
           }} 
        />
      </Box>
      <Box className="sectionContainer">
        <Box sx={webStyle.sections}>
          <Typography sx={webStyle.sectionLabel}>
            ACCOUNT
            </Typography> <Divider />
          <Box
            data-testid="accountNavigation"
            sx={{ display: "flex", 
              flexDirection: "column" 
            }}
          >
            {accountNav.map((navigation, index) => (
              <NavigationButton
              label={navigation.label}
                key={index}
                onClick={() => {
                  handleNavigate(navigation.to);
                }}
                isActive={activeItem === navigation.to}
                icon={navigation.icon}
              />
            ))}
          </Box>
        </Box>

        <Box sx={webStyle.sections}>
          <Typography sx={webStyle.sectionLabel}>
            SUPPORT
            </Typography>
          <Divider />
          <Box
            data-testid="supportNavigation"
            sx={{ 
                display: "flex",
               flexDirection: "column" 
              }}
          >
            {supportNav.map((navigation, index) => (
              <NavigationButton
                key={index}
                label={navigation.label}
                onClick={() => {
                  handleNavigate(navigation.to);
                }}
                isActive={activeItem === navigation.to}
                icon={navigation.icon}
              />
            ))}
          </Box>
        </Box>
        <Box
            sx={webStyle.userButton}
            onClick={() => {
              localStorage.clear();
              navigateTo('Login');
            }}
          >
            <img
              src={logoutIcon}
              alt="Logout"
              style={{ width: '24px', height: '24px' , color:"#F87171"}}
            />
            <Typography
              sx={webStyle.logoutButton}
            >
              Logout
            </Typography>
          </Box>
      </Box>
    </StyledSettingNavigationContainer>
  );
};

export default SettingSideNavigation;

const StyledSettingNavigationContainer = styled(Box)({
  width: "100%",
  display: "flex",
  background: "#ffffff",
  borderBottom: "1px solid #E2E8F0",
  flexDirection: "column",
  borderBottomRightRadius: "64px",
  borderRight: "1px solid #E2E8F0",
  overflowY: "auto",
  gap: "10px",
  "& .profileButton": {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    margin:"-8px 10px 3px 10px",
    padding: "10px 20px 10px 20px",
  },
  "& .navigationHeading": {
    height: "80px",
    display: "flex",
    paddingLeft: "30px",
    position: "relative",
    alignItems: "center",
    "& .closeDrawer": {
      display: "none",
      color: "#0074BF",
      position: "absolute",
      left: "30px",
      top: "29px",
    },
  },
  
  "& .sectionContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  "@media (max-width: 900px)": {
    borderBottomRightRadius: "0px",
    borderRight: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    "& .navigationHeading": {
      justifyContent: "center",
      "& .closeDrawer": {
        display: "block",
      },
    },
  },
});
const webStyle = {
  navigationTabHeading: {
    color: "#0074BF",
    fontFamily: "Montserrat",
    marginTop:'10px',
    fontSize: "20px",
    fontWeight: 700,
  },
  userButton : {
    cursor: 'pointer', 
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: '#F87171', 
    marginBottom:'20px',
    marginLeft:'28px',
  },
  sections: {
    display: "flex",
    flexDirection: "column",
  },
  logoutButton:{
    fontSize: '16px',
      fontFamily: 'Montserrat',
      color: '#F87171', 
      fontWeight: 500,
    
  },
  profileNameLogoContainer:(activeItem: string)=> ({
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    border: "2px solid #0074BF",
    display: "flex",
    backgroundColor: activeItem === "Profile" ? "#E0F2F8" : "transparent", 
    justifyContent: "center",
    alignItems: "center",
  }),
  sectionLabel: {
    fontFamily: "Montserrat",
    padding: "0px 5px 0px 35px",
    fontSiz: "14px",
    fontWeight: 700,
    color: "#94A3B8",
  },
  profileNameLogo: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#0074BF",
    fontFamily: "Montserrat",
  },
  
  userName: {
    fontSize: "16px",
    color: "#0F172A",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  editProfileLabel: {
    color: "#334155",
    fontWeight: 400,
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
 
};
