import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    selectedForm: string;
    accordform1Expanded: boolean;
    accordform2Expanded: boolean;
    step1Form: {
        airports: string[];
        options: Option[];
    }
    step2Form: {
        firstAccordionExpanded: boolean
        secondAccordionExpanded: boolean
        gradeOfCirtificates: string[];
        category: string[];
        class: string[];
        aircraftTypes: string[];
        addedAirCraftTypes: AircraftTypeItem[];
        gradeOptions: Option[];
        categoryOptions: Option[];
        classOptions: Option[];
        aircraftTypeOptions: Option[]
    }
    activeStep: number,
    step3Form: {
        designation: string[];
        examCost: string;
        paymentMethods: string;
        instructions: string;
        uploadedFiles: File[];
        designationOption: Option[],
        uploadedData: any
    }
}
interface Option {
    id: string;
    name: string;
}
// Customizable Area Start
interface AircraftTypeItem {
    id: string;
    value: string;
}

// Customizable Area End

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class AccountSetUpController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAirportsApiCallId: string = "";
    // Customizable Area End
    getDesignationApiCallId: string = "";
    postDesignationApiCallId: string = "";
    deleteDesignationApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // let role = new URLSearchParams(window.location.search).get('role')

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            selectedForm: 'form1',
            accordform1Expanded: false,
            accordform2Expanded: false,
            step1Form: {
                airports: [],
                options: []
            },
            step2Form: {
                firstAccordionExpanded: true,
                secondAccordionExpanded: true,
                gradeOfCirtificates: [],
                category: [],
                class: [],
                aircraftTypes: [],
                addedAirCraftTypes: [],
                gradeOptions: [
                    { id: '1', name: 'Private' },
                    { id: '2', name: 'Commercial' },
                    { id: '3', name: 'ATP' },
                    { id: '4', name: 'CFI' },
                    { id: '5', name: 'Instrument' }
                ],
                categoryOptions: [
                    { id: '1', name: 'Airplaine' },
                    { id: '2', name: 'Rotocraft' },
                    { id: '3', name: 'Glider' }
                ],
                classOptions: [
                    { id: '1', name: 'Single Engine Land' },
                    { id: '2', name: 'Single Engine Sea' },
                    { id: '3', name: 'Multi Engine Land' },
                    { id: '4', name: 'Multi Engine Sea' },
                    { id: '5', name: 'Helicopter' }
                ],
                aircraftTypeOptions: [
                    { id: '1', name: 'Cessna 310' },
                    { id: '2', name: 'Bell 206' },
                    { id: '3', name: 'Robinson R44' }
                ],
            },
            activeStep: 0,
            step3Form: {
                designation: [],
                examCost: "",
                paymentMethods: "",
                instructions: "",
                uploadedFiles: [],
                designationOption: [],
                uploadedData: []
            },
            // Customizable Area End
        };

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (this.getAirportsApiCallId === apiRequestCallId) {
                this.setState({
                    ...this.state,
                    step1Form: {
                        airports: this.state.step1Form.airports,
                        options: responseJson
                    }
                });
            }
            else if (this.getDesignationApiCallId === apiRequestCallId) {
                const options = responseJson?.map((item: any) => ({
                    id: item.id.toString(),
                    label: item.designation
                }));
                this.setState((prev) => ({
                    ...this.state,
                    step3Form: {
                        ...prev.step3Form,
                        designationOption: options,
                        designation: options?.length > 0 ? [...this.state.step3Form.designation, options[0].id] : this.state.step3Form.designation
                    }
                }));
            }
            // Add more else if blocks for other APIs
            else if (this.postDesignationApiCallId === apiRequestCallId) {
                let token = localStorage.getItem('accessToken');
                this.getDesignationRequest(token);
                this.handleNext();
            }
        }
    }

    getlabels = (options: Option[], selectedOptions: string[]) => {
        return selectedOptions
            .map((id: string) => options.find((option: Option) => option.id === id)?.name)
            .filter(Boolean) // Removes undefined values if an ID doesn't match
            .join(', ');
    }
    handleFileSelect = (fileList: FileList | null) => {
        if (!fileList) return;
        const newFiles = Array.from(fileList)
        this.setState(prevState => ({
            ...prevState,
            step3Form: {
                ...prevState.step3Form,
                uploadedFiles: [...prevState.step3Form.uploadedFiles, ...newFiles]
            }
        }));
    };

    handleNext = () => {
        this.setState(prevState => ({
            activeStep: Math.min(prevState.activeStep + 1, 2)
        }));
    };
    deleteAircraftTest = (id: string) => {
        this.setState((state) => ({
            step2Form: {
                ...state.step2Form,
                addedAirCraftTypes: state.step2Form.addedAirCraftTypes.filter(
                    (item) => item.id !== id
                ),
            },
        }),
            () => {
                if (this.state.step2Form.addedAirCraftTypes.length == 0) {
                    this.setState((state) => ({
                        step2Form: {
                            ...state.step2Form,
                            gradeOfCirtificates: [],
                            category: [],
                            class: [],
                            aircraftTypes: [],
                        },
                    }))

                }

            }
        );
    }
    handleAccordform1Expanded = (name: string) => {
        if (name === "accordform1Expanded") {
            this.setState((state) => ({
                step1Form: {
                    ...state.step1Form,
                    accordform1Expanded: !this.state.accordform1Expanded
                },
            }));
        } else {
            this.setState((state) => ({
                step1Form: {
                    ...state.step1Form,
                    accordform2Expanded: !this.state.accordform2Expanded
                },
            }));
        }
    }

    getLabelFromId(id: string, options: Option[]): string {
        const option = options.find(opt => opt.id === id);
        return option ? option.name : '';
    }

    generateCombinations() {

        const { gradeOfCirtificates, category, class: classType, aircraftTypes,
            gradeOptions, classOptions, categoryOptions, aircraftTypeOptions
        } = this.state.step2Form;
        let combinations: { id: string; value: string }[] = [];
        let id = 1;

        const selectedGrades = gradeOfCirtificates.map(id => this.getLabelFromId(id, gradeOptions));
        const selectedCategories = category.map(id => this.getLabelFromId(id, categoryOptions));
        const selectedClasses = classType.map(id => this.getLabelFromId(id, classOptions));
        // const selectedAircraftTypes = aircraftTypes.map(id => this.getLabelFromId(id, aircraftTypeOptions));

        selectedGrades.forEach(grade => {
            selectedCategories.forEach(category => {
                selectedClasses.forEach(classType => {
                    // selectedAircraftTypes.forEach(aircraftType => {
                    combinations.push({
                        id: id.toString(),
                        value: `${grade} - ${category} - ${classType}`
                    });
                    id++;
                    // });
                });
            });
        });
        return combinations;
    }


    handleStep1Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            ...state,
            step1Form: {
                ...state.step1Form,
                [name]: value,
            },
        }));
    }
    handleStep2Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            ...state,
            step2Form: {
                ...state.step2Form,
                [name]: value,
            },
        }), () => {
            // Only generate combinations if we have all required selections
            const form = this.state.step2Form;
            if (form.gradeOfCirtificates.length * form.category.length *
                form.class.length > 0) {
                this.setState(state => ({
                    ...state,
                    step2Form: { ...state.step2Form, addedAirCraftTypes: this.generateCombinations() }
                }));
            }
        });
    }
    handleStep3Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            ...state,
            step3Form: {
                ...state.step3Form,
                [name]: value,
            },
        }));
    }
    handleStep3InputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target
        this.setState((state) => ({
            ...state,
            step3Form: {
                ...state.step3Form,
                [name]: value,
            },
        }));
    }
    handleSubmit = () => {
        const currentIndex = this.state.step3Form.designationOption.findIndex(opt => opt.id === this.state.step3Form.designation[this.state.step3Form.designation.length - 1]);
        const nextIndex = currentIndex + 1;

        if (this.state.step3Form.designationOption.length - 1 === currentIndex) {
            this.props.navigation.navigate('Profile');
            // return;
        }

        this.setState((state) => ({
            ...state,
            step3Form: {
                ...state.step3Form,
                designation: nextIndex < this.state.step3Form.designationOption.length
                    ? [this.state.step3Form.designationOption[nextIndex].id]
                    : this.state.step3Form.designation,

                uploadedData: [
                    ...state.step3Form.uploadedData,
                    {
                        designation: state.step3Form.designation,
                        examCost: state.step3Form.examCost,
                        paymentMethods: state.step3Form.paymentMethods,
                        instructions: state.step3Form.instructions,
                        uploadedFiles: state.step3Form.uploadedFiles,
                    }
                ],
                examCost: "",
                paymentMethods: "",
                instructions: "",
                uploadedFiles: [],
            },
        }))
    }

    handlePrevious = () => {
        this.setState(prevState => ({
            activeStep: Math.min(prevState.activeStep - 1, 2)
        }));
    }

    handleAccordionChange = (type: string, isExpanded: boolean) => {
        this.setState((state) => ({
            ...state,
            step2Form: {
                ...state.step2Form,
                [type]: isExpanded,
            },
        }));
    };

    async componentDidMount() {
        let token = localStorage.getItem('accessToken');
        this.getAirportsRequest(token);
        this.getDesignationRequest(token);
    }

    getAirportsRequest = (token: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAirportsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAirportUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getDesignationRequest = (token: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDesignationApiCallId = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDesignationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    postDesignationRequest = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            designations: this.state.step2Form.addedAirCraftTypes.map((item) => ({ designation: item.value }))
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_categories/designation_instructions/create_multiple"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    };

    deleteDesignationRequest = (currentTestId: string) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            designations: this.state.step2Form.addedAirCraftTypes.map((item) => ({ designation: item.value }))
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_categories/designation_instructions/${currentTestId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    };

    // Customizable Area End
}
