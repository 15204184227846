import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  Grid
  // Customizable Area End
} from "@mui/material";

import { loginLogo, loginImage } from "./../../email-account-login/src/assets";
import { leftArrow } from "./assets";

import { Link } from 'react-router-dom';

// Customizable Area Start
import * as Yup from "yup";
import { Formik } from "formik";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";

// import For, { Props } from "./ForgotPasswordController.web";
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div className="Toastify"></div>
        <Grid container direction={'row'} >
          <Grid item xs={6}>
            <img src={loginLogo} style={{ height: "10%", width: "25%", padding: "15px" }} />
            <Grid sx={
              {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90%'
              }
            }>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'center'
                }}
              >
                <Link to={'/Login'}>
                  <img src={leftArrow} style={{ margin: '7px 18px', height: '19px', width: '19px' }} alt="left-arrow" />
                </Link>
                {!this.state.isFormSubmitted && <Formik
                  initialValues={{
                    type: "Technician",
                    email: "",
                  }}
                  data-test-id="formik"
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Invalid email format")
                      .required("Email is required"),
                    type: Yup.string().required("Type is required"),

                  })}
                  validateOnBlur={true}
                  validateOnChange={true}
                  onSubmit={() => {
                    this.handleSendRecoveryLink();
                  }}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Box sx={webStyle.formWrapper}>

                        <Box sx={webStyle.inputWrapper}>
                          <Typography variant="h6" sx={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 700, fontSize: "22px", color: "#001C55"

                          }}>
                            Forgot your password?
                          </Typography>
                          <Typography variant="h6" sx={{
                            margin: '15px 0px',
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 400, fontSize: "16px", color: "#0F172A"
                          }}>

                            No worries, we'll send a recovery link to your email.
                          </Typography>
                          <InputLabel style={webStyle.label} sx={{ color: '#334155' }}>Email</InputLabel>
                          <TextField
                            data-test-id={"email"}
                            name="email"
                            id="email"
                            value={props.values.email}
                            onChange={(event) => {
                              props.setFieldValue("email", event.target.value);
                              this.onValueChange("email", event.target.value);
                            }}
                            size="medium"
                            fullWidth={true}
                            variant="outlined"
                            type="text"
                            onBlur={props.handleBlur}
                            placeholder="Enter Email"
                            sx={{
                              "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #CBD5E1",
                                  borderWidth: "1px",
                                },
                              },
                              backgroundColor: "#ffffff",
                              color: "#94A3B8",
                              mb: "3px",
                            }}

                            InputProps={{
                              style: {
                                border: "1px solid #CBD5E1",
                                borderRadius: '20px',
                                fontWeight: 400,
                                width: "360px"

                              }
                            }}
                          />
                          {this.getErrorMessage(
                            props.touched,
                            props.errors,
                            "email"
                          )}
                        </Box>

                        <Button
                          type="submit"
                          sx={webStyle.btnStyle}
                          data-test-id="sendARecoveryLinkBtn"
                          variant="contained"
                        >
                          Send a recovery link
                        </Button>

                        <Typography variant="h6" align="center" sx={{
                          fontFamily: 'Montserrat, sans-serif',
                          fontWeight: 400, fontSize: "16px", color: "#0F172A",
                          display: "inline"
                        }}>
                          Back to
                          <Link to="/Login" style={{
                            fontFamily: 'Montserrat, sans-serif', display: "inline",
                            fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                            marginLeft: "7px"
                          }}>
                            Login
                          </Link >
                        </Typography>

                      </Box>
                    </form>
                  )}
                </Formik>}

                {this.state.isFormSubmitted && <Box sx={webStyle.formWrapper}>

                  <Box sx={webStyle.inputWrapper}>
                    <Typography variant="h6" sx={{
                      fontFamily: 'Montserrat, sans-serif',
                      fontWeight: 700, fontSize: "22px", color: "#001C55"

                    }}>
                      Check your inbox!
                    </Typography>
                    <Typography variant="h6" sx={{
                      margin: '15px 0px',
                      fontFamily: 'Montserrat, sans-serif',
                      fontWeight: 400, fontSize: "16px", color: "#0F172A"
                    }}>

                      We have sent a password reset link to john.doe@example.com
                    </Typography>

                  </Box>

                  <Button
                    type="submit"
                    sx={webStyle.recoveryBtn}
                    data-test-id="sendARecoveryLinkAgainBtn"
                    variant="outlined"
                  >
                    Resend e-mail
                  </Button>

                  <Typography variant="h6" align="center" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400, fontSize: "16px", color: "#0F172A",
                    display: "inline"
                  }}>Back to
                    <Link to="/Login" style={{
                      fontFamily: 'Montserrat, sans-serif',
                      display: "inline",
                      fontWeight: 700, fontSize: "16px",
                      color: "#0074BF", textDecoration: "none",
                      marginLeft: "7px"
                    }}>Login
                    </Link >
                  </Typography>
                </Box>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} padding={0}>
            <img src={loginImage} style={{ height: "100vh", width: "100%" }} />
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

const webStyle = {
  inputWrapper: {
    mb: 1,
    maxWidth: "360px",
    position: "relative",
    width: "100%",
  },
  recoveryBtn: {
    border: "2px solid #0074BF",
    backgroundColor: "#ffffff",
    marginTop: "25px",
    marginBottom: "20px",
    padding: '10px 20px',
    color: "#0074BF",
    borderRadius: '20px',
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "16px",
    height: "50px",
    width: "360px",
    textTransform: "none"
  },
  btnStyle: {
    height: "50px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none",
    width: "360px",
    marginTop: "25px",
    marginBottom: "50px",
    border: "none",
    borderRadius: "20px",
  },
  label: {
    marginTop: "3px",
    color: "#334155",
    fontSize: "16px",
    fontWeight: 600,
  },
  formWrapper: {
    display: "flex",
    height: '350px',
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
};